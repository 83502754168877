import React from 'react'
import QRCode from "react-qr-code";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function QRCodeVr(props) {

  const downloadQrCode = () => {
    var svgEl = document.getElementById("qrcode_svg");
    svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    var svgData = svgEl.outerHTML;
    var preface = '<?xml version="1.0" standalone="no"?>\r\n';
    var svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
    var svgUrl = URL.createObjectURL(svgBlob);
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    downloadLink.download = "vereinsrechner-qrcode.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  return (
    <>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={window.location.href}
          viewBox={`0 0 256 256`}
          level={'M'}
          title={"Vereinsrechner"}
          id={'qrcode_svg'}
          fgColor={'#000000'}
        />
        <div id="qrcode_bar" className="w3-container w3-bar w3-green ">
          <button 
            id="qrcode_bar_download" 
            className="w3-button w3-green w3-hover-green" 
            onClick={() => downloadQrCode()}
          >
            Download QR-Code
          </button>
          <button 
            id="qrcode_bar_close" 
            className="w3-button w3-dark-grey w3-hover-dark-grey" 
            onClick={() => props.hideQrCode()}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
    </>
  )
}

export default QRCodeVr;



