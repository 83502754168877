import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSackDollar, faTrash } from "@fortawesome/free-solid-svg-icons";

function ShoppingCart(props) {

  const [mySum, setMySum] = useState(0);

  useEffect(() => {
    // console.log(JSON.stringify(props.cartItems));
    let sum = 0;
    if (props.cartItems.length > 0) {
      props.cartItems.forEach((item) => (sum += item.preis));
    }
    setMySum(sum);
  }, [props.cartItems]);

  return (
    <div id="scart">
      <div id="scart_summary" className="w3-bar w3-green">
        <button
          id="scart_summe"
          className="w3-bar-item w3-center w3-button w3-green w3-hover-green w3-large"
          onClick={() => props.oeffnenWechselgeldRechner(mySum)}
        >
            {mySum.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })}
          
          <span className="w3-badge w3-margin-left w3-white w3-text-green">
            {props.cartItems?.length > 0 ? props.cartItems.length : 0}
          </span>
          {
            mySum !== 0 && 
            <span className="w3-margin-left">
              <FontAwesomeIcon icon={faSackDollar} />
            </span>
          }
        </button>
        <button
          id="scart_clear"
          className="w3-bar-item w3-right w3-button w3-dark-grey w3-hover-dark-grey w3-large"
          onClick={() => props.clearShoppingCart()}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>      
      </div>

      {props.cartItems?.length > 0 && (
        <div id="scart_article_list">{props.cartItems.map((item) => (
          <button
            className="w3-button w3-light-grey w3-hover-dark-grey w3-text-black w3-padding artikelbutton w3-large" 
            key={item.cartItemID}
            onClick={() => props.removeArticleFromShoppingCart(item)}
          >
            {item.bez}
            <br />
            <small>
              {item.preis.toLocaleString("de-DE", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
              })}
            </small>
          </button>
        )) }</div>
      )}
    </div>
  );
}
  
export default ShoppingCart;
