import * as ReactDOMClient from 'react-dom/client';
//import { BrowserRouter, Routes, Route } from "react-router-dom";
import './css/index.css';
import "./css/w3.css";
import App from './components/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const container = document.getElementById('root');

// Create a root.
const root = ReactDOMClient.createRoot(container);

//const Error404 = () => <h1>404 – Seite nicht gefunden</h1>;

/*
export default function All() {
    return ( <BrowserRouter>
        <Routes>
            <Route path="*" element={App} />
        </Routes>
    </BrowserRouter>  );
}
*/
// Initial render: Render an element to the root.
root.render(<App tab="home" />);

// During an update, there's no need to pass the container again.
root.render(<App tab="profile" />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();
