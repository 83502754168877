import React, {useState, useEffect, useRef} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark, faRightLeft } from "@fortawesome/free-solid-svg-icons";

function WechselgeldRechner(props) {

  const [myChangeAmount, setMyChangeAmount] = useState(0);
  const inputAmountRef = useRef(null);
  const scheine = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100, 150, 200];

  useEffect(() => {
    // inputAmountRef.current.focus();;
  }, []);

 const handleScheinKlick = (betrag) => {
    inputAmountRef.current.value = betrag
    berechnenWechselgeld(betrag);
 }

  const handleChangeAmount = (event) => {
    let betrag
    

    betrag = event.target.value;
    berechnenWechselgeld(betrag);
  }

  const berechnenWechselgeld = (betrag) => {
    let wechselgeld
    if (betrag != null) {
      wechselgeld = betrag - props.summe
    }
    if (wechselgeld != null && wechselgeld >= 0) {
      setMyChangeAmount(wechselgeld);
    }
    else {
      setMyChangeAmount(0);
    }
  }

  return (
    <div id="wechselgeldrechner">
      <div className="w3-container ">
        <span onClick={() => props.schliessenWechselgeldRechnerAbbruch()} 
        className="w3-button w3-display-topright">&times;</span>
      </div>
        
      <div className="w3-container w3-padding">
        <span>Gesamtsumme:&nbsp;{
                props.summe.toLocaleString("de-DE", {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                })
              }
        </span>
      </div>
      <div className="w3-container w3-padding">
        <span>Erhaltener Betrag:</span>
      </div>

      <div id="rueckgeldscheine" className="w3-container w3-padding">
        {
          scheine.filter((betrag) => (betrag >= props.summe)).map((betrag) => (
            <button
              className="w3-button w3-light-grey w3-hover-green w3-text-black geldscheinebutton w3-large" 
              key={betrag}
              onClick={() => handleScheinKlick(betrag)}
            >
              {betrag}&nbsp;&euro;
              <br />
              <small>
                
              </small>
            </button>
          )) 
        }
      </div>
      <div className="w3-container w3-padding">
        <input 
          className="w3-input w3-border w3-light-grey w3-text-dark-grey " 
          type="number" id="erhaltenerBetrag" autoComplete="off" inputMode="decimal"
          ref={inputAmountRef} onChange={handleChangeAmount} placeholder="Anderer Betrag..." 
        />
      </div>

      <div id="change_footer" className="w3-container w3-bar w3-green w3-margin-top">
        <button
          id="changeamount_footer_zurueck"
          className="w3-bar-item w3-center w3-button w3-dark-grey w3-large"
          onClick={() => props.schliessenWechselgeldRechnerAbbruch()}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <div
          id="wechselgeld_summe" 
          className="w3-bar-item w3-center w3-large"
        >
          <FontAwesomeIcon icon={faRightLeft}/>
          &nbsp;&nbsp;&nbsp;
          {
            myChangeAmount.toLocaleString("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 2,
            })
          }
        </div>
        <button
          id="changeamount_footer_erledigt"
          className="w3-bar-item w3-button w3-dark-grey w3-hover-dark-grey w3-large"
          onClick={() => props.schliessenWechselgeldRechnerErledigt()}
        >
          <FontAwesomeIcon icon={faCheck} />
        </button>  
      </div>
    </div>
  );
}
  
export default WechselgeldRechner;
