import React from "react";

// Kennzeichnet eine Trennlinie
const ARTIKEL_BEZ_TRENNER = "##"

function ArticleList(props) {
  //console.log("ArticleList.props.articles", props.articles)

  /*
  useEffect(() => {
  }, [props.articles]);
*/
  return (<div id="artikel">{props.articles.map((item) => {
    if (item.bez == ARTIKEL_BEZ_TRENNER) {
      return <hr />
    }
    return <button
      className="w3-button w3-light-grey w3-hover-green w3-text-black w3-padding artikelbutton w3-large" 
      key={item.articleID}
      onClick={() => props.addArticleToShoppingCart(item)}
    >
      {item.bez}
      <br />
      <small>
        {item.preis.toLocaleString("de-DE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        })}
      </small>
    </button>
  }
  )}</div>);
}

export default ArticleList;
