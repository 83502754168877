import React, { useState, useEffect } from "react";
import ArticleList from "./ArticleList";
import ShoppingCart from "./ShoppingCart";
import QrCodeVr from "./QrCodeVr";
import WechselgeldRechner from "./WechselgeldRechner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";

function App() {

  const [myOnline, setMyOnline] = useState(false);
  const [myRechnerAbgelaufen, setMyRechnerAbgelaufen] = useState(false);
  const [myRechnerAbgelaufenSeit, setMyRechnerAbgelaufenSeit] = useState("");
  const [myFetchFailed, setMyFetchFailed] = useState(false);
  const [myIsLoading, setMyIsLoading] = useState(false);
  const [myShowQrCode, setMyShowQrCode] = useState(false);
  const [myCartItemIndex, setMyCartItemIndex] = useState(0);
  const [myData, setMyData] = useState(null);
  const [myArticles, setMyArticles] = useState([]);
  const [myCartItems, setMyCartItems] = useState([]);
  const [myName, setMyName] = useState("Vereinsrechner");
  const [myMessage, setMyMessage] = useState("");
  const [myWechselgeldBerechnen, setMyWechselgeldBerechnen] = useState(false);
  const [mySumme, setMySumme] = useState(0);

  // Aus render-Funktion
  let title = myName;
  document.title = "Vereinsrechner | " + title;

  const addArticleToShoppingCart = (article) => {
    let index;
    let cardItems;
    let cartItem;

    index = myCartItemIndex + 1;
    setMyCartItemIndex(index);

    cartItem = {
      bez: article.bez,
      preis: article.preis,
      cartItemID: index,
    };

    cardItems = myCartItems.concat(cartItem);
    setMyCartItems(cardItems);
  }

  const removeArticleFromShoppingCart = (article) => {
    let cardItems = myCartItems.filter(
      (item) => item.cartItemID !== article.cartItemID
    );
    setMyCartItems(cardItems);
  }

  const oeffnenWechselgeldRechner = (summe) => {
    setMyWechselgeldBerechnen(true);
    setMySumme(summe);
  }

  const schliessenWechselgeldRechnerAbbruch = () => {
    setMyWechselgeldBerechnen(false);
  }

  const schliessenWechselgeldRechnerErledigt = () => {
    setMyWechselgeldBerechnen(false);
    clearShoppingCart();
  }

   const clearShoppingCart = () => {
    setMyCartItemIndex(0);
    setMyCartItems([]);
  }

  const removeMessage = () => {
    setMyMessage("");
  }

  const toggleQrCode = () => {
    setMyShowQrCode(!myShowQrCode);
  }

  const hideQrCode = () => {
    setMyShowQrCode(false);
  }

  /**
   * Einmalig Daten laden  
   */
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const uuid = queryParameters.get("uuid");
    const resource_name = uuid;
    let dataL; 
    let onlineL;

    onlineL = navigator.onLine;
    if (onlineL) {
      setMyIsLoading(true);
      (
        async () => {
          await fetch("https://api.vereinsrechner.de/get.php?uuid=" + resource_name)
          .then(res => res.json())
          .then(
            (res) => {
              localStorage.setItem(resource_name, JSON.stringify(res))
              setMyData(res);
              setMyFetchFailed(false); 
            },
            (err) => {
              console.log("Fehler bei Zugriff auf VR-API!")
              console.log(err)
              // Rechner wenn möglich aus dem lokalen Speicher holen
              try{
                dataL = JSON.parse(localStorage.getItem(resource_name));
                setMyData(dataL); 
              }
              catch(e) {  
                console.log("Lesen des VR aus dem lokalen Speicher nicht möglich")
              }
              setMyFetchFailed(true);
            }
          );
          setMyIsLoading(false); 
        }
      )()
    }
    else {
      console.log("Offline!")
      // Rechner wenn möglich aus dem lokalen Speicher holen
      try{
        dataL = JSON.parse(localStorage.getItem(resource_name));
        setMyData(dataL); 
      }
      catch(e) {
        console.log("Lesen des VR aus dem lokalen Speicher nicht möglich")
      }
      setMyFetchFailed(false);
    }
    setMyOnline(onlineL);
  }, []);

  /**
   * Abhängig von den gelesenen Daten wird der Titel, die Artikel und das Ablaufdatum ermittelt
   */
  useEffect(() => {
    let gueltig_bisL;
    let gueltig_bis_strL = "";
    let rechner_abgelaufenL = false;
    let nameL = ""; 
    let articlesL = [];
    let indexL;

    if (myData != null) {
      // Titel
      nameL = myData.name;
      // Artikel
      indexL = 0;
      articlesL = myData.artikel.map((item) => {
        indexL++;
        item.articleID = indexL;
        return item;
      });
      // Ablaufdatum
      gueltig_bis_strL = myData.gueltig_bis
      console.log("VR gültig bis aus JSON:", gueltig_bis_strL)
      rechner_abgelaufenL = false;
      if (
        gueltig_bis_strL !== undefined && 
        gueltig_bis_strL !== null && 
        gueltig_bis_strL !== ""
      ) {
        gueltig_bisL = new Date(gueltig_bis_strL);
        console.log("VR gültig bis:", gueltig_bisL)
        if (gueltig_bisL !== "Invalid Date") {
          const heute = new Date();
          if (gueltig_bisL.getTime() < heute.getTime()) {
            rechner_abgelaufenL = true;
          }
        }
      }
    }
    setMyRechnerAbgelaufen(rechner_abgelaufenL);
    setMyRechnerAbgelaufenSeit(gueltig_bis_strL)
    setMyName(nameL);
    setMyArticles(articlesL);
    console.log("Artikel", articlesL);
  }, [
      myData
    ]
  );

  /**
   * Abhängig von diversen Statusdaten Meldungen ausgeben
   */
  useEffect(() => {
    let messageL = "";

    if (myData == null) {
      if (!myIsLoading) {
        if (myOnline) {
          messageL = "Rechner kann nicht geladen werden!"
        }
        else {
          messageL = "Du bist offline, Rechner kann nicht geladen werden!"
        }
      }
    } else {
      if (myRechnerAbgelaufen) {
        messageL = "Dein Rechner ist abgelaufen seit " + myRechnerAbgelaufenSeit;
      }
      else if (myOnline) {
        if (myFetchFailed) {
          messageL = "Verbindung zum Server aktuell nicht möglich, deine Produkte sind evtl. nicht aktuell!"
        }
      }
      else {
        messageL = "Du bist offline, deine Produkte sind evtl. nicht aktuell!";
      }      
    }
    setMyMessage(messageL); 
  }, [
      myData, 
      myOnline, 
      myIsLoading, 
      myFetchFailed, 
      myRechnerAbgelaufen, 
      myRechnerAbgelaufenSeit
    ]
  );

  return (
    <>
      <div id="App-header" className="w3-top w3-bar w3-white w3-card">
        <div className="w3-bar-item" id="logo_header">
            <a href="https://vereinsrechner.de" target="_blank" rel="noreferrer" title="Vereinsrechner - Der Taschenrechner für euren Verein.">
              <img src="vereinsrechner_logo.svg" height="42" alt="Vereinsrechner - Der Taschenrechner für euren Verein" />
            </a>
        </div>
        <div className="w3-bar-item  w3-wide">Vereinsrechner<br/><span id="app-header-untertitel">{title}</span></div>
        <div className="w3-bar-item w3-wide w3-right">
          {
            (!myOnline || myFetchFailed) && 
            <div className="headerIcon">
              <FontAwesomeIcon icon={faCircleExclamation} style={{color: "#4CAF50",}} size="lg" />
            </div>
          }
          <div className="headerIcon" onClick={() => toggleQrCode()}>
            <FontAwesomeIcon icon={faQrcode} style={{color: "#a1a1a1",}} size="lg" />
          </div>
        </div>
      </div>
      <div id="header-empty"></div>
      { 
        myMessage !== "" && 
        <div className="infoMessage w3-panel w3-light-grey w3-leftbar w3-border-dark-gray w3-text-dark-grey w3-display-container">
          <span 
            onClick={() => removeMessage()} 
            className="w3-button w3-large w3-display-topright w3-padding-left"
          >
            &times;
          </span>
          <p>{myMessage}</p> 
        </div> 
      }
      { 
        myData == null && myIsLoading && 
        <div className="infoMessage w3-panel w3-light-grey w3-leftbar w3-border-dark-gray w3-text-dark-grey w3-display-container">
          <p>Daten werden geladen...</p> 
        </div> 
      }
      {
        myShowQrCode && 
        <div id="qrcode">
          <QrCodeVr title={title} hideQrCode={hideQrCode} />
        </div>
      }
      {
        !myShowQrCode &&
        !myRechnerAbgelaufen &&
        myData != null &&
        <div id="calculator">
          {
            !myWechselgeldBerechnen && 
            <>
              <ArticleList
                articles={myArticles}
                addArticleToShoppingCart={addArticleToShoppingCart}
              />
              <ShoppingCart
                  cartItems={myCartItems}
                  removeArticleFromShoppingCart={removeArticleFromShoppingCart}
                  clearShoppingCart={clearShoppingCart}
                  oeffnenWechselgeldRechner={oeffnenWechselgeldRechner}
              />
            </>
          }
          {
            myWechselgeldBerechnen && 
            <WechselgeldRechner 
              schliessenWechselgeldRechnerAbbruch={schliessenWechselgeldRechnerAbbruch}
              schliessenWechselgeldRechnerErledigt={schliessenWechselgeldRechnerErledigt}
              summe={mySumme}
            />
          }
        </div>    
      }
    </>
  );
};

export default App;